<nav class="ms-nav-fixed ms-pink">
  <div class="nav-wrapper">
    <a class="brand-logo" (click)="toHome()"><img src="/assets/img/logo.png" />MediaSpirit</a>
    <ul class="right hide-on-med-and-down">
      <li *ngIf="cropResTool === true">
        <a (click)="AddCropRes()" matTooltip="Add Crop Resolution">
          <i class="material-icons">crop</i>
        </a>
      </li>
      <li>
        <a routerLink="/status" matTooltip="MediaSpirit status">
          <i class="material-icons">timeline</i>
        </a>
      </li>
      <li *ngIf="allowNewTags === true">
        <a (click)="openAddTag()" matTooltip="Add new tag(s)">
          <i class="material-icons">label_outline</i>
        </a>
      </li>
      <li *ngIf="allowTagManager === true">
        <a routerLink="/tag-manager" matTooltip="Tag manager">
          <i class="material-icons">label</i>
        </a>
      </li>
      <li *ngIf="allowImageUpload === true">
        <a routerLink="/image-upload" matTooltip="Upload new image(s)">
          <i class="material-icons">file_upload</i>
        </a>
      </li>
      <li class="ms-image-count-container">
        <a routerLink="/image-search" matTooltip="Search image(s)">
          <span class="ms-image-count">{{ searchTotalRows$ | async }}</span>
          <i class="material-icons">view_module</i>
        </a>
      </li>
      <li>
        <a [matTooltip]="'Logout'" (click)="logout()">
          <i class="material-icons">exit_to_app</i>
        </a>
      </li>
    </ul>
    <a class="mobile-menu-button" (click)="showMobileMenuItems()">
      <i class="material-icons">{{ showMobileMenu === true ? 'close' : 'menu' }}</i>
    </a>
    <div *ngIf="showMobileMenu === true" class="mobile-menu">
      <ul>
        <li *ngIf="cropResTool === true">
          <a (click)="AddCropRes()" matTooltip="Add Crop Resolution">
            <i class="material-icons">crop</i>
          </a>
        </li>
        <li>
          <a (click)="showMobileMenuItems()" routerLink="/status" matTooltip="MediaSpirit status">
            <i class="material-icons">timeline</i>
          </a>
        </li>
        <li *ngIf="allowNewTags === true">
          <a (click)="openAddTag()" matTooltip="Add new tag(s)">
            <i class="material-icons">label_outline</i>
          </a>
        </li>
        <li *ngIf="allowTagManager === true">
          <a (click)="showMobileMenuItems()" routerLink="/tag-manager" matTooltip="Tag manager">
            <i class="material-icons">label</i>
          </a>
        </li>
        <li *ngIf="allowImageUpload === true">
          <a (click)="showMobileMenuItems()" routerLink="/image-upload" matTooltip="Upload new image(s)">
            <i class="material-icons">file_upload</i>
          </a>
        </li>
        <li class="ms-image-count-container">
          <a (click)="showMobileMenuItems()" routerLink="/image-search" matTooltip="Search image(s)">
            <span class="ms-image-count">{{ searchTotalRows$ | async }}</span>
            <i class="material-icons">view_module</i>
          </a>
        </li>
        <li>
          <a [matTooltip]="'Logout'" (click)="logout()">
            <i class="material-icons">exit_to_app</i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet class="ms-router-outlet"></router-outlet>
<notifier-container></notifier-container>
<img *ngIf="loading" src="/assets/img/logo-preloader.png" alt="..." class="logo-loading animate-flicker" />
